<template>
  <div class="staking staking-wrapper">
    <HeaderBanner/>

    <div class="ps-6 pe-6 max-width-1366">
      <NoticeCard/>

      <CustomTab
          :navList="navList"
          :selectedTab="selectedTab"
          @updateSelectTab="updateSelectTab"
      />

      <!--plans section-->
      <div v-if="loadingPlans" class="mt-16 min-h-600">
        <Loader/>
      </div>
      <div
          v-if="selectedTab === 1"
          class="d-flex justify-between align-center mt-12 pa-2 subscription-tab"
      >
        <Button
            :class="[
            'subscription-filter',
            activeFilter === 'ACTIVE' && 'active',
          ]"
            :disabled="loadingSubscribedPlans"
            @click="changeFilter('ACTIVE')"
        >{{ $t("staking.status_active") }}
        </Button
        >
        <Button
            :class="[
            'subscription-filter',
            activeFilter === 'CANCELED' && 'active',
          ]"
            :disabled="loadingSubscribedPlans"
            @click="changeFilter('CANCELED')"
        >{{ $t("staking.status_cancel") }}
        </Button
        >
        <Button
            :class="[
            'subscription-filter',
            activeFilter === 'TERMINATED' && 'active',
          ]"
            :disabled="loadingSubscribedPlans"
            @click="changeFilter('TERMINATED')"
        >{{ $t("staking.status_terminated") }}
        </Button
        >
      </div>
      <StakePlans
          v-if="plansData?.length > 0"
          :listType="selectedTab"
          :plansData="plansData"
          :totalPages="totalPages"
          :loadingDetail="loadingPlanDetail"
          :loadingMore="loadingMore"
          @stakeModal="stakeModal"
          @updateDetailByDuration="updateDetailByDuration"
          @loadMore="loadMore"
      />
      <div
          class="d-flex flex-direction-column justify-center align-center min-h-600"
          v-else
      >
        <BaseIcon name="NoData"/>
        <p class="text-center font-Regular-12 color-gray">
          {{ $t("staking.noData_table") }}
        </p>
      </div>
      <!--plans section-->

      <Modal
          v-model="showModal"
          footer-hide
          class=" stake-modal"
          :class-name="isLocaleLTR ? 'ltr' : 'rtl'"
          :width="screenWidth >= 992 ? 980 : 100"
          :fullscreen="screenWidth < 992"
          @on-cancel="cancelStakeModal"
      >
        <ModalHeader
            v-if="modalData"
            :content="modalData"
            :key="modalData.activeDuration"
            @updateActiveDuration="updateStakeModal"
        />
        <Divider class="mt-0 mb-0 bg-light-gray responsive-divider"/>
        <StakeDetail
            ref="stakeDetailRef"
            v-if="modalForm === 'view'"
            :content="modalData"
            @cancelModal="cancelModal"
            @renewalModal="renewalModal"
        />
        <StakeForm
            ref="stakeFormRef"
            v-else-if="modalForm === 'enter' && showModal"
            :content="modalData.details[0]"
            :coin="planDetails.coin"
            :wallet="wallet"
            :isLogin="isLogin"
            :loading="loadingStakeForm"
            :loadingBtn="loadingStakeButton"
            @closeModal="closeStakeForm"
            @subscribePlan="subscribePlan"
            @cancelModal="cancelModal"
            @renewalModal="renewalModal"
            @reloadDisclaimer="reloadDisclaimer"
        />
      </Modal>
      <Modal
          v-model="showCancel"
          footer-hide
          class="stake-modal"
          style="top: 10%"
          :class="isLocaleLTR ? 'ltr':'rtl'"
      >
        <div class="pt-12 ps-6 pe-6 pb-6">
          <p class="color-bone text-center font-Bold-16">
            {{ $t("staking.cancel_plan") }}
          </p>
          <p class="color-gray text-start font-Regular-16 pt-6">
            {{ $t("staking.cancel_plan_text") }}
          </p>
          <div
              class="mt-8 d-flex justify-between align-center"
              style="gap: 10%"
          >
            <Button
                class="exit-plan-btn"
                @click="cancelPlan"
                :loading="loadingCancelPlan"
            >
              <span>{{ $t("staking.cancel_plan") }}</span>
            </Button>
            <Button class="back-btn" @click="showCancel = false">
              <span>{{ $t("staking.back") }}</span>
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
          v-model="showAutoRenewal"
          footer-hide
          class="stake-modal"
          :class="isLocaleLTR ? 'ltr' : 'rtl'"
          style="top: 10%"
      >
        <div class="pt-12 ps-6 pe-6 pb-6">
          <p
              class="color-bone text-center font-Bold-16"
              v-if="activeAutoRenewal"
          >
            {{ $t("staking.activate_auto_renewal") }}
          </p>
          <p class="color-bone text-center font-Bold-16" v-else>
            {{ $t("staking.deactivate_auto_renewal") }}
          </p>
          <p
              class="color-gray text-start font-Regular-16 pt-6"
              v-if="activeAutoRenewal"
          >
            {{ $t("staking.active_auto_renewal_text") }}
          </p>
          <p class="color-gray text-start font-Regular-16 pt-6" v-else>
            {{ $t("staking.inactive_auto_renewal_text") }}
          </p>
          <div
              class="mt-8 d-flex justify-between align-center"
              style="gap: 10%"
          >
            <Button
                :class="activeAutoRenewal ? 'back-btn' : 'exit-plan-btn'"
                @click="updateAutoRenewal"
                :loading="loadingRenewalBtn"
            >
              <span v-if="activeAutoRenewal">{{ $t("staking.activate") }}</span>
              <span v-else>{{ $t("staking.deactivate") }}</span>
            </Button>
            <Button
                :class="activeAutoRenewal ? 'exit-plan-btn' : 'back-btn'"
                @click="showAutoRenewal = false"
            >
              <span>{{ $t("staking.back") }}</span>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import HeaderBanner from "@/components/Staking/HeaderBanner";
import NoticeCard from "@/components/Staking/NoticeCard";
import StakePlans from "@/components/Staking/StakePlans";
import ModalHeader from "@/components/Staking/ModalContent/ModalHeader";
import StakeForm from "@/components/Staking/ModalContent/StakeForm";
import StakeDetail from "@/components/Staking/ModalContent/StakeDetail";
import CustomTab from "@/components/Staking/CustomTab";
import Loader from "@/components/UI/LoadingComp";
import BaseIcon from "@/components/UI/BaseIcon/index";

export default {
  components: {
    HeaderBanner,
    NoticeCard,
    StakePlans,
    ModalHeader,
    StakeForm,
    StakeDetail,
    CustomTab,
    Loader,
    BaseIcon,
  },
  data() {
    return {
      loadingSubscribedPlans: false,
      loadingCancelPlan: false,
      loadingRenewalBtn: false,
      showModal: false,
      showCancel: false,
      showAutoRenewal: false,
      activeAutoRenewal: false,
      activeAutoRenewalDetailId: null,
      modalForm: null,
      modalData: null,
      selectedTab: 0,
      activeFilter: "ACTIVE",
      navList: [{id: 0, label: this.$t("staking.staking_plans")}],
      plansData: [] /* list of plans */,
      planDetails: [] /* list of details for each plan duraions */,
      loadingPlans: false,
      loadingMore: false,
      loadingStakeForm: false,
      loadingStakeButton: false,
      loadingPlanDetail: {planId: null, status: false},
      totalPages: false,
      pageNumber: 1,
      pageSize: 20,
      planId: null,
      userApprovedRenewal: null,
      wallet: {
        balance: 0.0,
        scale: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      getLoginStatus: "global/IS_LOGIN",
    }),
    isLogin() {
      return this.getLoginStatus;
    },
    screenWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    ...mapActions({
      getUserWallet: "userCenter/WALLET_SERVICE",
      getAllStaking: "staking/GET_ALL_STAKING_PLANS",
      getAllSubscribedStaking: "staking/GET_ALL_SUBSCRIBED_STAKING_PLANS",
      getDetailByDuration: "staking/GET_STAKING_DETAIL_BY_PLAN_DETAIL_ID",
      getDetailByPlan: "staking/GET_STAKING_DETAIL_BY_PLAN_ID",
      getSubscriptionDetailById: "staking/GET_SUBSCRIPTION_DETAIL_BY_ID",
      getDisclaimerByPlan: "staking/GET_STAKING_DISCLAIMER",
      changeAutoRenewal: "staking/POST_UPDATE_AUTO_RENEWAL",
      cancelStaking: "staking/POST_CANCEL_PLAN",
      subscribeStaking: "staking/POST_SUBSCRIBE_PLAN",
    }),
    cancelStakeModal() {
      if (this.modalForm !== "view") {
        this.$refs.stakeFormRef.clearForm();
      } else {
        this.$refs.stakeDetailRef.clearForm();
      }
    },
    cancelModal(planId) {
      this.showCancel = true;
      this.planId = planId
    },
    cancelPlan() {
      this.loadingCancelPlan = true;
      this.cancelStaking({subscriptionId: this.planId})
          .then(() => {
            this.$toast.success(this.$t("staking.success_request"));
            this.showCancel = false;
            this.showModal = false;
            this.plansData = [];
            if (this.pageNumber > 1) {
              this.pageSize = this.pageNumber * 20;
              this.pageNumber = 1;
            }
            this.getAllPlans();
            this.selectedTab = 0;
          })
          .catch((err) => this.$toast.error(err.message))
          .finally(() => (this.loadingCancelPlan = false));
    },
    renewalModal(planId, userApprovedRenewal,) {
      this.activeAutoRenewal = !userApprovedRenewal;
      this.showAutoRenewal = true;
      this.planId = planId
      this.userApprovedRenewal = userApprovedRenewal
    },
    updateAutoRenewal() {
      this.loadingRenewalBtn = true;
      let newData = !this.userApprovedRenewal;
      this.changeAutoRenewal({
        isAutoRenewal: this.userApprovedRenewal ? 0 : 1,
        subscriptionId: this.planId,
      }).then((response) => {
        if (response.data.code !== 500) {
          this.modalData = {
            ...this.modalData,
            details: [
              {
                ...this.modalData.details[0],
                userApprovedRenewal: newData,
              },
            ],
          };
          this.$refs.stakeFormRef.updateSelectTab(1)
          this.$refs.stakeFormRef.$refs.switchRef.currentValue = newData
        } else {
          this.$toast.error(this.$t("staking.unknown_mistake"));
        }

      })
          .finally(() => {
            this.showAutoRenewal = false;
            this.loadingRenewalBtn = false;
          });
    },
    closeStakeForm() {
      this.showModal = false;
    },
    async getAllPlans() {
      return this.getAllStaking({
        pageNo: this.pageNumber,
        pageSize: this.pageSize,
        type: !this.isLogin ? "public/" : "",
      })
          .then(async (response) => {
            this.totalPages = response.data.data.totalPages;
            await response.data.data.content.map((plan) => {
              if (plan.durations.length > 0) this.plansData.push(plan);
            });
          })
          .catch((err) => {
            this.$toast.error(err.message);
          });
    },
    async getSubscribedPlans() {
      return this.getAllSubscribedStaking({
        status: this.activeFilter,
        pageNo: this.pageNumber,
        pageSize: this.pageSize,
      })
          .then(async (response) => {
            this.totalPages = response.data.data.totalPages;
            this.plansData = [...this.plansData, ...response.data.data.content];
          })
          .catch((err) => {
            this.$toast.error(err.message);
          }).finally(() => this.loadingSubscribedPlans = false);
    },
    loadMore() {
      this.loadingMore = true;
      ++this.pageNumber;
      this.selectedTab === 1
          ? this.getSubscribedPlans().finally(() => (this.loadingMore = false))
          : this.getAllPlans().finally(() => (this.loadingMore = false));
    },
    stakeModal({type, planId, durationId}) {
      this.loadingStakeForm = true;
      let api;
      if (this.selectedTab === 0) {
        api = this.getDetailByPlan({
          type: !this.isLogin ? "public/" : "",
          stakingPlanId: planId,
        });
      } else if (this.selectedTab === 1) {
        api = this.getSubscriptionDetailById({subscriptionId: planId});
      }

      api
          .then(async (response) => {
            if (this.selectedTab === 0) {
              const temp = this.plansData.find(
                  (obj) => obj.stakingPlanId === planId
              );

              this.planDetails = await response.data.data;
              const activeDetail = this.planDetails.details.find(
                  (obj) => obj.durationData.planDetailId === durationId
              );
              this.modalData = {
                coinDto: this.planDetails.coin,
                activeDuration: durationId,
                stakingPlanId: temp.stakingPlanId,
                description: temp.description,
              };
              if (activeDetail.hasOwnProperty("subscription")) {
                this.modalData.details = [activeDetail.subscription];
                this.modalData.status = temp.status;
                // this.modalData.cancelable = activeDetail.durationData.cancelable;
                // this.modalData.description = temp.description;
                if (temp.durations.length > 0) {
                  this.modalData.durations = temp.durations;
                }
              } else {
                this.modalData.details = [activeDetail.stakingPlanDetail];
                this.modalData.details[0].description = temp.description;
                this.modalData.durations = temp.durations;
                // this.modalData.cancelable =
                //   activeDetail.stakingPlanDetail.cancelable;
                this.getDisclaimerByPlan(this.modalData.details[0].id).then(
                    (res) => {
                      this.modalData.details[0].disclaimer =
                          res.data.data.disclaimer;
                    }
                );
              }
            } else {
              const temp = this.plansData.find(
                  (obj) => obj.subscriptionId === planId
              );
              this.modalData = {
                coinDto: {
                  coinUnit: temp.coinUnit,
                  displayName: temp.displayName,
                  logoUrl: temp.logoUrl,
                  rewardScale: temp.rewardScale,
                  stakeScale: temp.stakeScale,
                },
                details: [response.data.data],
                description: temp.planDescription,
                status: response.data.data.status,
              };
            }

            if (this.isLogin) {
              this.getUserWallet(this.modalData.coinDto.coinUnit).then(
                  (response) => {
                    if (response.data.data !== null) {
                      this.wallet = {
                        balance: response.data.data.strBalance,
                        scale: response.data.data.withdrawScale,
                      };
                    } else {
                      this.wallet = {
                        balance: 0.0,
                        scale: 0,
                      };
                    }
                  }
              );
            }
            this.showModal = true;
            this.modalForm = type;
          })
          .catch((err) => {
            this.$toast.error(err.message);
          })
          .finally(() => {
            this.loadingStakeForm = false;
          });
    },
    updateDetailByDuration({planId, durationId}) {
      this.loadingPlanDetail = {planId: planId, status: true};
      const index = this.plansData.findIndex(
          (obj) => obj.stakingPlanId === planId /* finding plan object */
      );
      this.getDetailByDuration({
        stakingPlanDetailId: durationId,
        type: !this.isLogin ? "public/" : "",
      })
          .then((response) => {
            this.plansData[index].details[0] =
                response.data.data; /* replacing new detail */
            let newItem = this.plansData[index];
            this.plansData.splice(index, 1, newItem); /* updating list */
          })
          .catch((err) => {
            this.$toast.error(err.message);
          })
          .finally(
              () => (this.loadingPlanDetail = {planId: planId, status: false})
          );
    },
    updateSelectTab(id) {
      this.plansData = [];
      this.pageNumber = 1;
      this.pageSize = 20;
      id === 1 ? this.getSubscribedPlans() : this.getAllPlans();
      this.selectedTab = id;
    },
    updateStakeModal(durationId) {
      const activeDetail = this.planDetails.details.find(
          (obj) => obj.durationData.planDetailId === durationId
      );
      this.modalForm = activeDetail.hasOwnProperty("subscription")
          ? "view"
          : "enter";
      let newData = activeDetail.hasOwnProperty("subscription")
          ? activeDetail.subscription
          : activeDetail.stakingPlanDetail;
      this.modalData = {
        ...this.modalData,
        details: [
          {
            ...this.modalData.details[0],
            ...newData,
          },
        ],
        activeDuration: durationId,
      };
    },
    subscribePlan(payload) {
      this.loadingStakeButton = true;
      this.subscribeStaking({
        planDetailId: this.modalData.details[0].id,
        ...payload,
      })
          .then(() => {
            this.$toast.success(this.$t("staking.success_request"));
            setTimeout(() => {
              window.location.reload();
            }, 1000)

          })
          .catch((err) => this.$toast.error(err.message))
          .finally(() => {
            this.loadingStakeButton = false;
          });
    },
    changeFilter(type) {
      this.activeFilter = type;
      this.plansData = [];
      this.pageNumber = 1;
      this.pageSize = 20;
      this.loadingSubscribedPlans = true
      this.getSubscribedPlans();
    },
    reloadDisclaimer() {
      this.getDisclaimerByPlan(this.modalData.details[0].id).then(
          (res) => {
            this.modalData.details[0].disclaimer =
                res.data.data.disclaimer;
          }
      );
    }
  },
  created() {
    this.loadingPlans = true;
    if (this.isLogin) {
      this.navList.push({id: 1, label: this.$t("staking.my_plans")});
    }
    this.getAllPlans().finally(() => (this.loadingPlans = false));
  },
};
</script>

<style lang="scss">
// .staking {
.bg-light-gray {
  background: #62738c;
}

.bg-dark-gray {
  background: #a2aebf;
}

.bg-dim-gray {
  background: #192330;
}

.bg-deep-gray {
  background: #394350;
}

.bg-dark-blue {
  background: #141e2c;
}

.color-white {
  color: #fff !important;
}

.color-bone {
  color: #ececec;
}

.color-gray {
  color: #a2aebf;
}

.color-light-gray {
  color: #a3aebf;
}

.color-dark-gray {
  color: #62738c;
}

.color-green {
  color: #00b947;
}

.color-red {
  color: #ff544a;
}

.color-smoke {
  color: #d9d9d9;
}

.color-orange {
  color: #f1ac1c;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-30 {
  line-height: 30px;
}

.h-330 {
  height: 400px;
}

.h-273 {
  height: 273px;
}

.h-250 {
  height: 250px;
}

.h-100 {
  height: 100%;
}

.min-h-600 {
  min-height: 600px;
}

.w-100 {
  width: 100%;
}

.max-width-522 {
  max-width: 522px;
}

.max-width-1366 {
  max-width: 1366px;
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
}

.z-index-100 {
  z-index: 100;
}

.responsive-divider {
  @media (max-width: 400px) {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.staking {
  .subscription-tab {
    background-color: #19232f;
    border-radius: 8px;
    max-width: fit-content;
    gap: 8px;
    @media only screen and (max-width: 550px) {
      max-width: 100%;
    }

    .subscription-filter {
      background-color: transparent;
      color: #a3aebf;

      &.active {
        background-color: #f1ac1c;
        color: #000;
        border-radius: 4px;
        font-size: 16px;
      }
    }
  }
}
</style>
